<script>
import { mapGetters, mapActions } from 'vuex';
import Swal from 'sweetalert2'

export default {
  
  components: {
   
  },
  data() {
    return {
      editedItemDir: null,
      editedItemOld: null,
      editedItemNew: null,
      updating: false,
      deleting: false
   };
  },
  created(){
    
  },
  computed: {
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('directoryItems', {
      allTags: 'tags'
    }),
    dirTags(){
      let res = [];
      this.directories.forEach(dir => {
        if(this.allTags[dir.id] && this.allTags[dir.id].length > 0) {
          res.push({ dir, tags: this.allTags[dir.id] });
        }
      });
      return res;
    }
  },
  methods: {
    ...mapActions('directoryItems', {
      updateTag: 'updateTag',
      deleteTag: 'deleteTag'
    }),
    startEdit(dir, tag) {
      this.editedItemDir = dir;
      this.editedItemOld = tag; 
      this.editedItemNew = tag;
      this.$nextTick(()=> {
        if(this.$refs.tagInput) {
          if(this.$refs.tagInput.focus)
            this.$refs.tagInput.focus();
          else if(this.$refs.tagInput.length > 0) {
            this.$refs.tagInput[0].focus();
          }
        }
      });
    },
    stopEdit() {
      this.editedItemDir = null;
      this.editedItemOld = null; 
      this.editedItemNew = null;
    },
    async onUpdateTag() {
      if(this.editedItemNew) {
        this.updating = true;
        await this.updateTag( {discriminator: this.editedItemDir.id, oldName: this.editedItemOld, newName: this.editedItemNew });
        this.updating = false;
        this.stopEdit();
      }
    },
    async onDeleteTag(dir, name) {
      this.deleting = true;
      await this.deleteTag( {discriminator: dir.id, name: name });
      this.deleting = false;
      this.stopEdit();
    },
    tryToDelete(dir, name) {
       Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          this.onDeleteTag(dir, name);
        }
      });
    },
  }
};
</script>

<template>
  <div>
    <div class="card-header pt-0">
      <h4 class="card-title">
        {{$t('settings.tagsTabTitle')}}
        <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin" v-if="deleting"/>
      </h4>
    </div>
    <span class="text-muted p-2" v-if="dirTags.length === 0">{{$t('common.noRecordsFound')}}</span>
    <div v-for="(dirTag, index) in dirTags" :key="index">
      <h5 class="font-size-16 mb-1 mt-4">
       {{$t('common.directoryEntity')}} - {{dirTag.dir.name}}
      </h5>
      <div class="table-responsive">
        <table class="table table-nowrap">
          <tbody>
            <tr v-for="(tag, tagIndex) in dirTag.tags" :key="tagIndex + ' - ' + index">
              <template v-if="dirTag.dir !== editedItemDir || tag !== editedItemOld">
                <td>
                  <button  class="btn btn-light btn-sm me-2" @click="startEdit(dirTag.dir, tag)" :disabled="deleting">
                    <font-awesome-icon icon="fa-solid fa-pen" />
                    
                    <div class="text-sm-end d-none d-md-inline-block ms-2">
                      {{ $t("common.editItem") }}
                    </div>
                  </button>
                  <span class="badge badge-soft-dark">
                    <font-awesome-icon icon="fa-solid fa-tag" /> {{tag}}
                  </span>
                  
                  <button  class="btn btn-light btn-sm ms-2 float-end text-danger" :disabled="deleting" @click="tryToDelete(dirTag.dir, tag)">
                    <font-awesome-icon icon="fa-solid fa-floppy-disk" />
                 
                    <div class="text-sm-end d-none d-md-inline-block ms-2">
                      {{ $t("common.deleteItem") }}
                    </div>
                  </button>
                </td>
              </template>
              <template v-if="dirTag.dir === editedItemDir && tag === editedItemOld">
                <td>
                  <div class="input-group">
                    <button  class="btn btn-primary btn-sm me-2" :disabled="!editedItemNew || updating" @click="onUpdateTag">
                      <font-awesome-icon icon="fa-solid fa-floppy-disk" v-if="!updating"/>
                      <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin" v-if="updating"/>
                      <div class="text-sm-end d-none d-md-inline-block ms-2">
                        {{ $t("common.saveButtonTitle") }}
                      </div>
                    </button>
                  
                    <input class="form-control form-control-sm" v-model="editedItemNew" ref='tagInput'/>
                    
                    <button  class="btn btn-secondary btn-sm ms-2" @click="stopEdit()">
                      <font-awesome-icon icon="fa-solid fa-xmark" />
                      <div class="text-sm-end d-none d-md-inline-block ms-2">
                        {{ $t("common.closeButtonTitle") }}
                      </div>
                    </button>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
