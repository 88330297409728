<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Tags from "./tabs/tags.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Tags
},
  data() {
    return {
      pagetitle: this.$t('settings.pageTitle'),
      tab: 'tags'
    };
  },
  created(){
    
  },
  mounted() {
    document.title = this.pagetitle;
  },
  methods: {
  
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="nav flex-column nav-pills"  role="tablist" aria-orientation="vertical">
              <a class="nav-link mb-2 active" id="tagsTab" data-bs-toggle="pill" href="#tagsTab" role="tab" aria-controls="v-pills-home" aria-selected="true">
                {{$t("settings.tagsTabTitle")}}
              </a>  
            </div>
          </div>
          <div class="col-md-9">
            <div class="tab-content text-muted mt-4 mt-md-0">
              <div class="tab-pane fade show active" id="tagsTab" role="tabpanel" aria-labelledby="v-pills-tagsTab">
                <Tags></Tags>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
